













import Vue from 'vue';
import Component from 'vue-class-component';
import SurveyInfo from '@/components/surveys/SurveyInfo.vue';
import PageTitle from '@/components/ui/PageTitle.vue';
import Page from '@/components/ui/Page.vue';
import CreatedModifiedCell from '@/components/ui/CreatedModifiedCell.vue';
import SubmissionList from '@/components/submissions/SubmissionList.vue';
import SurveyNavigation from '@/components/surveys-edit/SurveyNavigation.vue';
import {SurveyResource} from '@/interfaces';
import {surveyModule} from '@/store/modules';
import Spinner from '@/components/ui/Spinner.vue';
import {Route} from '@/enums';

@Component({
  components: {
    Spinner,
    SurveyNavigation,
    SubmissionList,
    CreatedModifiedCell,
    Page,
    PageTitle,
    SurveyInfo,
  },
})
export default class Submissions extends Vue {
  private route = Route;
  get surveyId(): string {
    return this.$route.params.id;
  }

  get survey(): SurveyResource {
    return surveyModule.find(this.surveyId);
  }

  get loading(): boolean {
    return surveyModule.loading;
  }

  async created(): Promise<void> {
    if (!this.survey) {
      await surveyModule.loadById(this.surveyId);
    }
  }
}
